<style scoped>
.content {
  border-bottom: 1px solid var(--borderColor);
  position: relative;
  padding: 5px 0 5px;
}

.content_btn {
  padding: 8px 15px;
  border-radius: 3px;
  color: white;
  font-weight: bold;
  font-size: 12px;
}
.content_btn_del {
  background: #e05454;
}
.content_btn_edit {
  background: #7390ef;
}
.content_btn_view {
  background: green;
}
.content_btn_preview {
  background: #006180;
}
</style>

<template>
  <div>
    <common-top
      title="稿件列表"
      :needEmit="true"
      @back="back"
      :isShowSearch="false"
    ></common-top>

    <div v-if="isUserPm" style="padding: 0 10px; margin-top: 10px">
      <div class="content" v-for="(data, index) in listCon" :key="index">
        <rn-gaojian :contentData="data"></rn-gaojian>

        <div style="display: flex; justify-content: flex-end">
          <div class="content_btn content_btn_edit" @click="toEdit(data._cid)">
            编辑
          </div>
          <div
            class="content_btn content_btn_del"
            style="margin-left: 10px"
            @click="showDialog(data)"
          >
            删除
          </div>
          <div
            v-if="data.review == '2'"
            class="content_btn content_btn_view"
            style="margin-left: 10px"
            @click="publishReview(index, data)"
          >
            提交审核
          </div>
          <div
            class="content_btn content_btn_preview"
            style="margin-left: 10px"
            v-if="data.contentLink"
          >
            <a
              style="color: white; outline: none; text-decoration: none"
              :href="data.contentLink"
              >预览</a
            >
          </div>
        </div>
      </div>
    </div>

    <no-pass-com v-show="!isUserPm"> </no-pass-com>

    <load-more
      @refresh="onRefresh"
      :load-status="isLoadMore"
      :isShow="loadMoreShow"
    ></load-more>
    <dia-logliu :show="dailogshow" :options="dailogoptions"></dia-logliu>

    <div v-if="isConfirm && curCon">
      <confirm
        :title="`该删除不可恢复，是否确认删除 ${curCon.title}`"
        @sure="toDel"
        @cancel="isConfirm = false"
      ></confirm>
    </div>
  </div>
</template>

<script>
/* eslint-disable  */
import debug from "debug";
import Vue from "vue";
import moment from "moment";
import { wxConPost, getUserPm } from "../../api/httpApi";
import _ from "lodash";

import loadMore from "../../components/loadMoreData.vue";
import rnGaojianFooter from "../../components/rnGaojianFooter.vue";
import rnGaojian from "../../components/rnGaojian.vue";
import diaLogliu from "../../components/dialogLiu.vue";
import confirm from "../../components/commonConfirm.vue";
import commonTop from "../../components/commonTop.vue";
import noPassCom from "../../components/noPassCom.vue";

const _d = debug("@pages:likeList");

moment.locale("zh-cn");

export default {
  name: "tougaoList",
  data() {
    return {
      listCon: [],
      activeIndex: -1,
      isLoadMore: true,
      loadMoreShow: false,
      isConfirm: false,
      curCon: null,
      isUserPm: true,

      dailogshow: false, // 提示框显示
      duration: 1000,
      dailogoptions: {
        content: "",
        autoClose: true,
        textColor: "#ccc",
      }, // 提示框选项
    };
  },

  methods: {
    // 审核状态显示
    getReviewStatus(review) {
      switch (review) {
        case "0":
          return "待审核";
        case "1":
          return "审核通过";
        case "-1":
          return "未通过";
        case "2":
          return "待审核";
        default:
          return "待审核";
      }
    },
    back() {
      this.$router.replace("/tougaoEditor");
    },
    async onRefresh() {
      const limit = this.$store.state.limit ? this.$store.state.limit : 10;

      const query = {
        column: this.$store.state.column,
        limit: this.$store.state.limit,
        skip: this.listCon.length,
        sort: { updated: -1 },
      };

      try {
        const ret = await wxConPost(
          "/services/gttt-content-mgt/tougao/contentList",
          query
        );
        const dataList = [];
        const data = ret.value;
        if (data.length == 0) {
          this.loadMoreShow = true;
          this.isLoadMore = false;
        } else {
          for (const item of data) {
            let columntype = "text";
            if (item.video) {
              columntype = "video";
            } else if (item.img.length > 0) {
              columntype = "image";
            }
            let listData = item;
            Object.assign(listData, {
              type: columntype,
              keywordsStr: item.keywords ? item.keywords.join(" ") : "",
            });

            dataList.unshift(listData);
          }

          let arr1 = _.sortBy(dataList, "time");
          arr1 = _.reverse(_.sortBy(arr1, "colnumTop"));

          this.listCon = this.listCon.concat(arr1);

          this.loadMoreShow = true;
          this.isLoadMore = true;
        }
      } catch (error) {}
    },

    // 获取栏目信息
    async getList() {
      const query = {
        limit: this.$store.state.limit,
        sort: { updated: -1 },
      };

      try {
        const ret = await wxConPost(
          "/services/gttt-content-mgt/tougao/contentList",
          query
        );
        const data = ret.value;
        const dataList = [];
        if (data && data.length == 0) {
          this.loadMoreShow = true;
          this.isLoadMore = false;
        } else {
          for (const item of data) {
            let columntype = "text";
            if (item.video) {
              columntype = "video";
            } else if (item.img.length > 0) {
              columntype = "image";
            }
            let listData = item;
            Object.assign(listData, {
              type: columntype,
              keywordsStr: item.keywords ? item.keywords.join(" ") : "",
            });

            dataList.unshift(listData);
          }

          this.listCon = _.reverse(_.sortBy(dataList, "updated"));

          // 显示加载
          this.loadMoreShow = true;
          // 显示加载
          this.isLoadMore = true;
        }
      } catch (error) {}
    },
    diaLogFun(msg) {
      this.dailogshow = true;
      this.dailogoptions.content = msg;
      this.dailogoptions.textColor = "white";
      setTimeout(() => {
        this.dailogshow = false;
      }, 2000);
    },

    toEdit(cid) {
      this.$router.push({
        path: "/tougaoEditor",
        query: {
          cid,
        },
      });
    },
    toUrl(url) {
      this.$router.push({
        path: url,
      });
    },

    showDialog(curCon) {
      this.curCon = curCon;
      this.isConfirm = true;
    },
    async toDel() {
      console.log("----", this.curCon);
      if (!(this.curCon && this.curCon._cid)) {
        this.diaLogFun("请选择文章");
        return;
      }

      try {
        const res = await wxConPost(
          "/services/gttt-content-mgt/tougao/deleteContent",
          {
            _cid: this.curCon._cid,
          }
        );
        this.diaLogFun("删除成功");
        this.getList();
        this.curCon = null;
      } catch (e) {
        this.diaLogFun("删除异常");
        this.curCon = null;
      }
    },
    // 提交审核
    async publishReview(index, curData) {
      try {
        // 设置提交审核状态
        await wxConPost("/services/gttt-content-mgt/tougao/publishReview", {
          _cid: curData._cid,
        });
        this.diaLogFun("操作成功");
        // 修改本条数据状态
        curData["review"] = "0";
        Vue.set(this.listCon, index, curData);
      } catch (e) {
        this.diaLogFun("操作成功");
      }
    },
    async getUserDataPm() {
      // get 用户权限
      const userPm = getUserPm();
      if (!userPm) {
        // 任何情况下，用户权限均重新获取最新的，storage 中的权限紧做异常使用
        try {
          const result = await wxConPost("/services/gttt-user-mgt/pm/userPm", {
            onlyPm: true,
          });
          localStorage.token = result.newToken; // 重写token
          // 数据写入storage中
          // 该 token 由 网关和后台组成
          this.setLocalStorage("userMgtPm", result.userPm);

          const _userPm = getUserPm();
          if (!(_userPm.pmSuper || result.userPm.bContentTouGao)) {
            this.$router.replace("/noPasspage");
          }

          // 通知数据获取成功
        } catch (e) {
          console.log("------ee", e);
          _d("xxxxxxxxxxx获取用户其他数据Err", e);
        }
      } else if (!(userPm.pmSuper || userPm.bContentTouGao)) {
        this.$router.replace("/noPasspage");
      }
    },
  },

  async created() {
    const userPm = getUserPm("稿件管理");
    if (userPm) {
      if (userPm && (userPm.bContentTouGao || userPm.pmSuper)) {
        this.isShowDept = true;
        this.getList();
      }
    } else {
      this.isUserPm = false;
    }
  },
  beforeCreate() {
    document.title = "媒体看广铁";
  },

  components: {
    loadMore,
    rnGaojianFooter,
    noPassCom,
    commonTop,
    rnGaojian,
    diaLogliu,
    confirm,
  },
  filters: {
    dateFormat(time) {
      return moment(time).startOf("minutes").fromNow();
    },
  },
};
</script>
